import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useHookForm } from "../../../hooks/hook-form";
import { getPorts } from "../../../services/contentService";
import AutoComplete from "../../AutoComplete";
import Dropdown from "../../dropdown/index.jsx";
import FormFooter from "../../formFooter";
import RequestQuoteInput from "../input";
import styles from "./index.module.scss";
import DropdownWithCheckboxSelect from "../../DropdownWithCheckboxSelect.jsx";

const RequestQuoteStepOne = () => {
  const {
    inputsData,
    stepOneData,
    setStepOneData,
    infoIcon,
    freight,
    schedulesInputsValues,
    prevLocation,
    isPolTrucking,
    isPodTrucking,
  } = useOutletContext();

  const isInsurance = () => {
    const requiredServices = stepOneData?.requiredServices;
    if (requiredServices !== undefined) {
      return requiredServices?.some((obj) => obj.title === "Insurance");
    } else {
      return false;
    }
  };

  const { handleSubmit, errors, PodFreeTimeRegister, invoiceValueRegister } =
    useHookForm({
      // isPodFreeTime: true,
      isInvoiceValue: isInsurance(),
      values: {
        // PodFreeTime: stepOneData?.PodFreeTime ?? "",
      },
      defaultValues: {
        // PodFreeTime: stepOneData?.PodFreeTime ?? "",
      },
    });

  const [ports, setPorts] = useState({ results: [] });
  const [portsPod, setPortsPod] = useState({ results: [] });
  const [portsPage, setPortsPage] = useState(1);
  const [portsPagePod, setPortsPagePod] = useState(1);
  const [portsInputValue, setPortsInputValue] = useState("");
  const [portsInputValuePod, setPortsInputValuePod] = useState("");
  const [loading, setLoading] = useState(false);
  const [portCode, setPortCode] = useState(null);

  const navigate = useNavigate();

  // For Pol
  const getAllPorts = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPorts({
          ...response.data,
          results:
            portsPage > 1
              ? [...ports.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };
  // For Pod
  const getAllPortsPod = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPortsPod({
          ...response.data,
          results:
            portsPagePod > 1
              ? [...portsPod.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };

  // For Pol
  const handlePortsScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPage < ports.total_pages) {
      setPortsPage((prevPage) => prevPage + 1);
    }
  };
  // For Pod
  const handlePortsScrollPod = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPagePod < portsPod.total_pages) {
      setPortsPagePod((prevPage) => prevPage + 1);
    }
  };

  // For Pol
  const getQueryParams = () => {
    const queryParams = [];
    portsPage > 1 && queryParams.push(`page=${portsPage}`);
    portsInputValue && queryParams.push(`value=${portsInputValue}`);
    portCode && queryParams.push(`locode=${portCode}`);
    return queryParams.join("&");
  };
  // For Pod
  const getQueryParamsPod = () => {
    const queryParamsPod = [];
    portsPagePod > 1 && queryParamsPod.push(`page=${portsPagePod}`);
    portsInputValuePod && queryParamsPod.push(`value=${portsInputValuePod}`);
    portCode && queryParamsPod.push(`locode=${portCode}`);
    return queryParamsPod.join("&");
  };

  // For Pol
  const handlePolChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValue("");
    setStepOneData({ ...stepOneData, pol: newValue });
  };
  // For Pod
  const handlePodChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValuePod("");
    setStepOneData({ ...stepOneData, pod: newValue });
  };
  const handleShippingLineChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectOption = inputsData?.shipping_lines.find(
      (option) => option.name === value
    );
    setStepOneData({ ...stepOneData, shippingLine: selectOption });
  };
  const handlePODFreeTimeChange = (event) => {
    const {
      target: { value },
    } = event;
    setStepOneData({ ...stepOneData, podFreeTime: value });
  };

  const handleInvoiceValueChange = (event) => {
    const {
      target: { value },
    } = event;
    setStepOneData({ ...stepOneData, invoiceValue: value });
  };

  const handleRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setStepOneData((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  // This Function For Freight to be always selected.
  // const handleRequiredServicesChange = (event, item) => {
  //   const { checked } = event.target;
  //   const valueKey = "id";

  //   setStepOneData((prevData) => {
  //     const requiredServices = prevData.requiredServices || [];
  //     const freightService = { id: 1, name: "Freight" };

  //     // Start with filtered array based on the change
  //     let updatedServices = checked
  //       ? [...requiredServices, item]
  //       : requiredServices.filter(
  //           (service) => service[valueKey] !== item[valueKey]
  //         );

  //     // Ensure `freightService` is at the first index
  //     if (
  //       !updatedServices.some(
  //         (service) => service[valueKey] === freightService[valueKey]
  //       )
  //     ) {
  //       updatedServices = [freightService, ...updatedServices];
  //     }

  //     return {
  //       ...prevData,
  //       requiredServices: updatedServices,
  //     };
  //   });
  // };

  const handleFormSubmit = (data) => {
    //console.log("data", data);
    setStepOneData({ ...stepOneData, ...data });
    navigate(
      `/request-quote/${
        isPolTrucking() || isPodTrucking()
          ? "trucking-details"
          : "cargo-details"
      }`
    );
  };

  // For Pol
  useEffect(() => {
    const params = getQueryParams();
    getAllPorts(params);
  }, [portsInputValue, portsPage]);

  // For Pod
  useEffect(() => {
    const params = getQueryParamsPod();
    getAllPortsPod(params);
  }, [portsInputValuePod, portsPagePod]);

  useEffect(() => {
    console.log("step one data", stepOneData);
  }, [stepOneData]);
  useEffect(() => {
    // console.log("freight", freight);
  }, [freight]);
  useEffect(() => {
    // console.log("prevLocation", prevLocation);
  }, [prevLocation]);

  // AutoFilling (POL & POD & ShippingLine) From The Data That Comes From Ship Schedules.
  useEffect(() => {
    if (Object.keys(freight).length > 0) {
      const matchedshippingLine = inputsData?.shipping_lines?.find(
        (option) => option.scac === freight?.shipping_line
      );
      setStepOneData({
        ...stepOneData,
        eta: freight?.ETA ?? null,
        etd: freight?.ETD ?? null,
        search_id: freight?.search_id ?? null,
        prevLocation: prevLocation,
        pol:
          schedulesInputsValues?.pol === "" ? null : schedulesInputsValues?.pol,
        pod:
          schedulesInputsValues?.pod === "" ? null : schedulesInputsValues?.pod,
        shippingLine: schedulesInputsValues?.shouldShowShippingLine
          ? matchedshippingLine
          : null,
      });
      // console.log("schedulesInputsValues", schedulesInputsValues);
      // console.log("matchedshippingLine", matchedshippingLine);
      // console.log("freight", freight);
      // console.log("freightLength", Object.keys(freight).length);
    }
  }, [freight, inputsData]);

  const containerStyle = {
    border: "1px solid #d9dcdc",
    minHeight: "2.9rem",
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles["form-container"]}
    >
      <div
        className={`${styles["form-container-row"]} ${styles["form-container-row-title"]}`}
      >
        <h2>Shipping</h2>
      </div>
      <div className={styles["form-container-row"]}>
        <AutoComplete
          optionLabel="value"
          handleScroll={handlePortsScroll}
          handleInputChange={(event, val) => {
            setPortsInputValue(val);
            setPortsPage(1);
          }}
          inputValue={portsInputValue}
          options={ports?.results}
          label={"Port Of Loading (POL)*"}
          handleChange={handlePolChange}
          value={stepOneData?.pol ?? null}
          placeholder={"Enter port of loading"}
        />
        <AutoComplete
          optionLabel="value"
          handleScroll={handlePortsScrollPod}
          handleInputChange={(event, val) => {
            setPortsInputValuePod(val);
            setPortsPagePod(1);
          }}
          inputValue={portsInputValuePod}
          options={portsPod?.results}
          label={"Port Of Discharge (POD)*"}
          handleChange={handlePodChange}
          value={stepOneData?.pod ?? null}
          placeholder={"Enter port of discharge"}
        />
      </div>
      <div className={styles["form-container-row"]}>
        <Dropdown
          label={"Shipping Line"}
          value={stepOneData?.shippingLine?.name ?? ""}
          onChange={handleShippingLineChange}
          placeholder={"Select shipping line"}
          placeholderShowed={true}
          options={inputsData?.shipping_lines}
        />
        <RequestQuoteInput
          placeholder="Enter POD free time"
          label="POD Free Time"
          unit="days"
          type="number"
          name="PodFreeTime"
          // error={errors["PodFreeTime"]}
          // formRegister={PodFreeTimeRegister}
          onChange={handlePODFreeTimeChange}
          value={stepOneData?.podFreeTime ?? ""}
        />
      </div>
      <div className={styles["form-container-row"]}>
        <DropdownWithCheckboxSelect
          label={"Required Services*"}
          value={stepOneData?.requiredServices ?? []}
          onChange={handleRequiredServicesChange}
          placeholder={"Select required services"}
          options={inputsData?.requested_services}
          handleRequiredServicesChange={handleRequiredServicesChange}
          stepOneData={stepOneData}
          containerStyle={containerStyle}
          className={styles.container}
        />
        <RequestQuoteInput
          placeholder="Enter invoice value"
          label="Invoice Value*"
          unit="US Dollars"
          type="number"
          name="invoiceValue"
          error={errors["invoiceValue"]}
          formRegister={invoiceValueRegister}
          onChange={handleInvoiceValueChange}
          disabled={!isInsurance()}
          value={stepOneData?.invoiceValue ?? ""}
        />
      </div>
      <div className={styles["form-container-info"]}>
        <img src={infoIcon} alt="info" />
        <p>
          Fields with * are mandatory. If you have a favourable shipping line,
          or required Free Time at POD please mention. In case you need
          insurance services please enter cargo invoice value.
        </p>
      </div>
      <FormFooter
        disableBackButton={true}
        disableNextButton={
          !stepOneData.pol ||
          !stepOneData.pod ||
          // !stepOneData.shippingLine ||
          !stepOneData.requiredServices?.length
        }
        buttonText="Next"
        handleBackButton={() => navigate(-1)}
      />
    </form>
  );
};

export default RequestQuoteStepOne;
