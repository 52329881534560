// src/redux/slices/searchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    originPort: "",
    destinationPort: "",
    readyOn: "",
    cargoType: "",
    container: { type: "", number: 1 },
    isOriginChanged: false,
    isDestinationChanged: false,
    isReadyOnChanged: false,
    isCargoTypeChanged: false,
    isContainerChanged: false,
  },
  reducers: {
    setOriginPort: (state, action) => {
      state.originPort = action.payload;
    },
    setDestinationPort: (state, action) => {
      state.destinationPort = action.payload;
    },
    setReadyOn: (state, action) => {
      state.readyOn = action.payload;
    },
    setCargoType: (state, action) => {
      state.cargoType = action.payload;
    },
    setContainer: (state, action) => {
      state.container = action.payload;
    },

    setIsOriginChanged: (state, action) => {
      state.isOriginChanged = action.payload;
    },
    setIsDestinationChanged: (state, action) => {
      state.isDestinationChanged = action.payload;
    },
    setIsReadyOnChanged: (state, action) => {
      state.isReadyOnChanged = action.payload;
    },
    setIsCargoTypeChanged: (state, action) => {
      state.isCargoTypeChanged = action.payload;
    },
    setIsContainerChanged: (state, action) => {
      state.isContainerChanged = action.payload;
    },
  },
});

export const {
  setOriginPort,
  setDestinationPort,
  setReadyOn,
  setCargoType,
  setContainer,

  setIsOriginChanged,
  setIsDestinationChanged,
  setIsReadyOnChanged,
  setIsCargoTypeChanged,
  setIsContainerChanged,
} = searchSlice.actions;
export default searchSlice.reducer;
