import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import OriginPort from "../originPort";
import DestinationPort from "../destinationPort";
import searchIcon from "../../assets/search.png";
import searchBlue from "../../assets/search-blue.svg";
import arrowIcon from "../../assets/caret_icon.svg";
import DateInput from "../dateInput";
import { getCargos } from "../../services/testService";
import Search from "../../assets/svgs/Search";
import DropdownMultipleWithCount from "../DropdownMultipleWithCount";
import CargoDropdown from "../CargoDropdown";
import formatDateToYYYYMMDD from "../../utils/formatDate";
import AutoComplete from "../AutoComplete";
import { getPorts, getRequestQuoteData } from "../../services/contentService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOriginPort,
  setDestinationPort,
  setReadyOn,
  setCargoType,
  setContainer,
  setIsOriginChanged,
  setIsDestinationChanged,
  setIsReadyOnChanged,
  setIsContainerChanged,
} from "../../store/slices/searchSlice";
import { CircularProgress } from "@mui/material";

export default function FreightSearch({
  handleSearch,
  isPressed,
  shouldShowPrice,
  quotationsearchResults,
  searchLoading,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cargos, setCargos] = useState([]);
  const [date, SetDate] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const fetchCarogs = async () => {
    const response = await getCargos();
    //console.log("cargos", response.data.cargo);
    setCargos(response.data.cargo);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchCarogs();
  }, []);

  // The Origin and Destination Ports New Logic From Request Quotation.
  const [ports, setPorts] = useState({ results: [] });
  const [portsPod, setPortsPod] = useState({ results: [] });
  const [portsPage, setPortsPage] = useState(1);
  const [portsPagePod, setPortsPagePod] = useState(1);
  const [portsInputValue, setPortsInputValue] = useState("");
  const [portsInputValuePod, setPortsInputValuePod] = useState("");
  const [loading, setLoading] = useState(false);
  const [portCode, setPortCode] = useState(null);
  const [portsValues, setportsValues] = useState({});
  const [inputsData, setInputsData] = useState([]);
  const [rows, setRows] = useState([{ id: 1, containers: [] }]);
  // For Pol
  const getAllPorts = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPorts({
          ...response.data,
          results:
            portsPage > 1
              ? [...ports.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };
  // For Pod
  const getAllPortsPod = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPortsPod({
          ...response.data,
          results:
            portsPagePod > 1
              ? [...portsPod.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };
  // For Pol
  const handlePortsScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPage < ports.total_pages) {
      setPortsPage((prevPage) => prevPage + 1);
    }
  };
  // For Pod
  const handlePortsScrollPod = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPagePod < portsPod.total_pages) {
      setPortsPagePod((prevPage) => prevPage + 1);
    }
  };
  // For Pol
  const getQueryParams = () => {
    const queryParams = [];
    portsPage > 1 && queryParams.push(`page=${portsPage}`);
    portsInputValue && queryParams.push(`value=${portsInputValue}`);
    portCode && queryParams.push(`locode=${portCode}`);
    return queryParams.join("&");
  };
  // For Pod
  const getQueryParamsPod = () => {
    const queryParamsPod = [];
    portsPagePod > 1 && queryParamsPod.push(`page=${portsPagePod}`);
    portsInputValuePod && queryParamsPod.push(`value=${portsInputValuePod}`);
    portCode && queryParamsPod.push(`locode=${portCode}`);
    return queryParamsPod.join("&");
  };
  // For Pol
  const handlePolChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValue("");
    dispatch(setOriginPort(newValue));
    dispatch(setIsOriginChanged(true));
    setportsValues({ ...portsValues, pol: newValue });
  };
  // For Pod
  const handlePodChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValuePod("");
    dispatch(setDestinationPort(newValue));
    dispatch(setIsDestinationChanged(true));
    setportsValues({ ...portsValues, pod: newValue });
  };
  // For Pol
  useEffect(() => {
    const params = getQueryParams();
    getAllPorts(params);
  }, [portsInputValue, portsPage]);
  // For Pod
  useEffect(() => {
    const params = getQueryParamsPod();
    getAllPortsPod(params);
  }, [portsInputValuePod, portsPagePod]);

  const handleDateChange = (value) => {
    SetDate(formatDateToYYYYMMDD(value));
    dispatch(setReadyOn(formatDateToYYYYMMDD(value)));
    dispatch(setIsReadyOnChanged(true));
  };

  const getInputsData = async () => {
    const response = await getRequestQuoteData();
    if (response.status === 200) {
      setInputsData(response?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    getInputsData();
  }, []);

  const handleContainerChange = (index, event) => {
    const {
      target: { value },
    } = event;

    const lastSelected = value[value.length - 1]; // Get the last selected container

    // Set updatedContainers to include only the last selected container
    const updatedContainers = lastSelected
      ? [{ ...lastSelected, count: lastSelected.count ?? 1 }]
      : [];

    // Update the specific row's containers
    const updatedRows = [...rows];
    updatedRows[index].containers = updatedContainers;
    setRows(updatedRows);
    dispatch(setIsContainerChanged(true));
    // console.log(updatedContainers[0].name);

    dispatch(
      setContainer({
        type: updatedContainers[0].type,
        number: updatedContainers[0].count,
      })
    );
  };

  const handleCount = (id, op) => {
    const newArr = [];
    for (const row of rows) {
      for (const item of row.containers) {
        if (item.type === id) {
          if (op === "+") {
            if (item.count < 99) item.count = item.count + 1;
          } else {
            if (item.count > 1) item.count = item.count - 1;
          }
        }
        if (item.count !== 0) {
          newArr.push(item);
        }
      }
    }

    dispatch(
      setContainer({
        type: newArr[0]?.type,
        number: newArr[0]?.count,
      })
    );
  };

  // AutoFilling Ship Schedules Search Values When The User Back From Request Quotation.
  useEffect(() => {
    if (quotationsearchResults) {
      setportsValues({
        ...portsValues,
        pol:
          quotationsearchResults?.pol === ""
            ? null
            : quotationsearchResults?.pol,
        pod:
          quotationsearchResults?.pod === ""
            ? null
            : quotationsearchResults?.pod,
      });
      SetDate(quotationsearchResults?.startingFromDate);
      if (quotationsearchResults?.container) {
        setRows([{ id: 1, containers: [quotationsearchResults?.container] }]);
      } else {
        setRows([{ id: 1, containers: [] }]);
      }
    }
  }, [quotationsearchResults]);

  const containerStyle = {
    border: "0.8px solid #d9dcdc",
    minHeight: "3.3rem",
    height: "auto",
    padding: "6.5px 0px",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
  };
  const containersLabelStyle = {
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgb(13, 14, 14)",
  };

  return (
    <div className={styles["search"]}>
      <AutoComplete
        optionLabel="value"
        handleScroll={handlePortsScroll}
        handleInputChange={(event, val) => {
          setPortsInputValue(val);
          setPortsPage(1);
        }}
        inputValue={portsInputValue}
        options={ports?.results}
        label={"Port Of Loading"}
        handleChange={handlePolChange}
        value={portsValues?.pol ?? null}
        placeholder={"Enter port of loading"}
        inputClassName={styles["input-container-styles"]}
        className={styles["input-styles"]}
        labelClassName={styles["label-styles"]}
        quoteForm={false}
      />
      <div onClick={handleSearch} className={styles["search-arrow"]}>
        <img alt="search" src={arrowIcon} />
      </div>
      <AutoComplete
        optionLabel="value"
        handleScroll={handlePortsScrollPod}
        handleInputChange={(event, val) => {
          setPortsInputValuePod(val);
          setPortsPagePod(1);
        }}
        inputValue={portsInputValuePod}
        options={portsPod?.results}
        label={"Port Of Discharge"}
        handleChange={handlePodChange}
        value={portsValues?.pod ?? null}
        placeholder={"Enter port of discharge"}
        inputClassName={styles["input-container-styles"]}
        className={styles["input-styles"]}
        labelClassName={styles["label-styles"]}
        quoteForm={false}
      />

      {shouldShowPrice ? (
        <>
          {rows.map((row, index) => (
            <DropdownMultipleWithCount
              label={"Containers"}
              value={row?.containers ?? []}
              onChange={(selectedContainers) =>
                handleContainerChange(index, selectedContainers)
              }
              placeholder={"Select container and number of"}
              options={inputsData.cargos_type}
              handleCount={handleCount}
              className={styles.containersStyles}
              labelStyle={containersLabelStyle}
              containerStyle={containerStyle}
            />
          ))}
        </>
      ) : (
        <CargoDropdown
          options={cargos}
          quotationsearchCargo={quotationsearchResults?.cargo}
        />
      )}
      <div className={styles["date-search-container"]}>
        <DateInput
          iconColor={"dark"}
          handleChange={handleDateChange}
          label="Starting from"
          placeholderShowed={false}
          placeholder={"Select date"}
          className={styles["search-date"]}
          dateLabel={styles["date-label"]}
          theme={true}
          disablePast={true}
          value={date ?? null}
        />
        {searchLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles["search-action-container"]}>
            <button
              onClick={handleSearch}
              className={
                isPressed
                  ? styles["search-action-pressed"]
                  : styles["search-action"]
              }
              disabled={isPressed}
            >
              <Search isPressed={isPressed} />
            </button>
          </div>
        )}
      </div>
      {/* <OriginPort
        ports={ports}
        setSelectedOriginPort={setSelectedOriginPort}
        setSearchPort={setOriginPort}
      /> */}
      {/* <DestinationPort
        ports={ports}
        setSearchPort={setDestinationPort}
        setSelectedDestinationPort={setSelectedDestinationPort}
      /> */}
      {/* <Cargo cargos={cargos} cargo={cargo} setCargo={setCargo} /> */}
      {/* <DropdownMultipleWithCount
        label={"Cargo"}
        value={cargo}
        onChange={handleContainerChange}
        placeholder={"Select cargo "}
        options={cargos}
        handleCount={handleCount}
        labelStyle={labelStyle}
        background={false}
        containerStyle={containerStyles}
        className={styles["cargo-styles"]}
        customPlaceholderColor={styles["placeholder-color"]}
        // valueKey="id"
      /> */}
    </div>
  );
}
