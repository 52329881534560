import axios from "axios";

export const getFreights = (
  token,
  originPort,
  destinationPort,
  readyOn,
  cargoType
) => {
  return axios
    .post(
      "/content/freights/integration",
      {
        cargo_type: cargoType,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          origin_port: originPort,
          destination_port: destinationPort,
          ready_on: readyOn,
          // cargo_type: cargoType,
        },
      }
    )
    .then((response) => response)
    .catch((err) => err?.response);
};

export const getCargos = () => {
  return axios
    .get("/content/containers/getAll")
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getPorts = (port = "") => {
  return axios
    .get("/content/ports", {
      params: {
        // country: port,
        value: port,
      },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getGlobalConfigs = () => {
  return axios
    .get("/global/config")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
